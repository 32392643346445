import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => (
  <div className="footer">
    <div>
      <div>2023 by Yunyi Zhu</div>
    </div>
  </div>
);

export default Footer;