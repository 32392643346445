import React from 'react'
import PigeonClick from './pigeonclick';

const SpencerPage = () => (
 <div>
    <div>Hey darling! I miss you. :) Please click for some pigeons! 🩷 </div>
    <PigeonClick />
 </div>
);

export default SpencerPage;